import React from "react";
import { makeStyles } from "tss-react/mui";
import Dashboard from "./dashboard";
import { Route, Routes } from "react-router-dom";
import RequireAuthentication from "./RequireAuthentication";
import Profile from "./profile";
import { useProfile } from "./auth0";
import Authorize from "./Authorize";

const useStyles = makeStyles()((theme) => ({
  content: {
    overflow: "auto",
  },
  container: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default function MainPanel(): JSX.Element {
  const { profile } = useProfile();
  const { classes } = useStyles();

  return (
    <main className={classes.content}>
      <section className={classes.container}>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuthentication>
                <Dashboard />
              </RequireAuthentication>
            }
          />
          <Route
            path="dashboard"
            element={
              <RequireAuthentication>
                <Dashboard />
              </RequireAuthentication>
            }
          />
          {profile !== null ? (
            <Route
              path="user/profile/self"
              element={
                <RequireAuthentication>
                  <Profile user={profile} />
                </RequireAuthentication>
              }
            />
          ) : null}
          <Route path="authorize" element={<Authorize />} />
        </Routes>
      </section>
    </main>
  );
}
