import React, { useState, Fragment } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useAuth0, useProfile } from "../auth0";
import MenuComponent from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Tooltip from "@mui/material/Tooltip";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: grey[100],
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function Menu(): JSX.Element {
  const { classes } = useStyles();
  const { isAuthenticated, logout } = useAuth0();
  const { profile } = useProfile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    logout();
    handleMenuClose();
  };

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/logo.svg`}
              alt="IO"
              height={64}
            />
          </Link>
        </Typography>

        {isAuthenticated && (
          <Fragment>
            <Tooltip title="Dashboard">
              <IconButton
                aria-label="dashboard"
                component={Link}
                to="/"
                size="large"
              >
                <DashboardIcon />
              </IconButton>
            </Tooltip>

            <IconButton
              data-testid="profile-menu-button"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              size="large"
            >
              <Avatar
                data-testid="avatar"
                alt={profile?.displayName ?? "N/A"}
                src={profile?.avatarUrl ?? undefined}
              >
                {profile?.displayName?.[0] ?? profile?.email?.[0]}
              </Avatar>
            </IconButton>
            <MenuComponent
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={anchorEl != null}
              onClose={handleMenuClose}
            >
              {profile !== null ? (
                <MenuItem
                  onClick={handleMenuClose}
                  component={Link}
                  to="/user/profile/self"
                >
                  User profile
                </MenuItem>
              ) : null}
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </MenuComponent>
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
}
