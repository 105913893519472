import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { OwnUserProfile } from "../../auth0";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  displayName: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default function CoreProfileData(props: {
  user: OwnUserProfile;
}): JSX.Element {
  const { classes } = useStyles();
  const { user } = props;
  const link = `mailto:${user.email}`;

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar
            data-testid="avatar"
            alt={user.displayName ?? undefined}
            src={user.avatarUrl ?? undefined}
          />
        </Grid>
        <Grid item xs container direction="column">
          <Grid item xs container direction="row">
            <Typography gutterBottom variant="subtitle1">
              <Link href={link}>{user.email}</Link>
            </Typography>
            {user.displayName ? (
              <Typography
                color="textSecondary"
                variant="subtitle1"
                className={classes.displayName}
              >
                ({user.displayName})
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
