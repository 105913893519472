import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  IdToken,
  Auth0Provider,
  Auth0ContextInterface,
  Auth0ProviderOptions,
  useAuth0,
  Auth0Context,
} from "@auth0/auth0-react";

if (!process.env.REACT_APP_AUTH0_DOMAIN) {
  throw new Error("REACT_APP_AUTH0_DOMAIN environmental variable is missing");
}

if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
  throw new Error(
    "REACT_APP_AUTH0_CLIENT_ID environmental variable is missing",
  );
}

export const settings = Object.freeze({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
});

export const CLAIM_NAMESPACE = "https://io.selladore.co/";

export function getCustomClaim<T>(
  token: IdToken | undefined | null,
  name: string,
): T | undefined {
  if (!token) {
    return;
  }
  return token[`${CLAIM_NAMESPACE}${name}`] as T | undefined;
}

export { Auth0Provider, useAuth0 };

export type OwnUserProfile = {
  id: string;
  displayName?: string | null | undefined;
  email: string;
  avatarUrl?: string | null | undefined;
};

type ProfileContextType = {
  profile: OwnUserProfile | null;
};

export const ProfileContext = createContext<ProfileContextType>({
  profile: null,
});

type ProfileContextOptions = {
  profile?: OwnUserProfile;
};

export const useProfile: () => ProfileContextType = () =>
  useContext(ProfileContext);

export function ProfileProvider({
  children,
  profile: user,
}: {
  children: ReactNode;
} & ProfileContextOptions): JSX.Element {
  const { isAuthenticated, isLoading, user: auth0user } = useAuth0();
  const [profile, setProfile] = useState<OwnUserProfile | null>(user ?? null);

  useEffect(() => {
    if (isLoading || !isAuthenticated || user !== undefined) {
      return;
    }
    if (
      auth0user !== undefined &&
      auth0user.sub !== undefined &&
      auth0user.email !== undefined
    )
      setProfile({
        id: auth0user.sub,
        displayName: auth0user.nickname,
        email: auth0user.email,
        avatarUrl: auth0user.picture,
      });
  }, [isAuthenticated, isLoading, auth0user, user]);

  return (
    <ProfileContext.Provider
      value={{
        profile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export function TestAuth0Provider({
  children,
  profile,
  ...data
}: Partial<Auth0ContextInterface> &
  Partial<Auth0ProviderOptions> &
  ProfileContextOptions): JSX.Element {
  return (
    <Auth0Context.Provider value={data as Auth0ContextInterface}>
      <ProfileProvider profile={profile ?? undefined}>
        {children}
      </ProfileProvider>
    </Auth0Context.Provider>
  );
}

export function createTestToken(
  value: string,
): Auth0ContextInterface["getAccessTokenSilently"] {
  return (() =>
    Promise.resolve(
      value,
    )) as unknown as Auth0ContextInterface["getAccessTokenSilently"];
}
