import React, { createRef, useState } from "react";
import { settings } from "../../sisense/embed";
import EmbeddedDashboard from "./EmbeddedDashboard";
import { makeStyles } from "tss-react/mui";
import { LoadingState } from "./loading-state";

const useStyles = makeStyles()((theme) => ({
  hidden: {
    visibility: "hidden",
    height: 0,
    width: 0,
  },
  frame: {
    border: 0,
    width: "100%",
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "fixed",
    paddingBottom: theme.spacing(10),
  },
}));

export default function Dashboard(): JSX.Element {
  const { classes } = useStyles();
  const ref = createRef<HTMLIFrameElement>();
  const [loadingState, setLoadingState] = useState<LoadingState | null>(
    "initial",
  );

  return (
    <div className={classes.container}>
      <iframe
        id={settings.frameId}
        key={settings.frameId}
        title={settings.frameId}
        ref={ref}
        className={loadingState === null ? classes.frame : classes.hidden}
        hidden={loadingState !== null}
      />
      <EmbeddedDashboard
        key={`${settings.frameId}-dashboard`}
        frame={ref}
        loadingState={loadingState}
        onLoadingStateChange={setLoadingState}
      />
    </div>
  );
}
