import React, { Fragment } from "react";
import TopMenu from "./TopMenu";

export default function Menu(): JSX.Element {
  return (
    <Fragment>
      <TopMenu />
    </Fragment>
  );
}
