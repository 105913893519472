import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Menu from "./menu";
import MainPanel from "./MainPanel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingIndicator from "./components/LoadingIndicator";
import { useAuth0 } from "./auth0";
import { BrowserRouter } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const muiCache = createCache({
  key: "mui",
  prepend: true,
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#01639b",
      light: "#4f90cc",
      dark: "#00396c",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#de784c",
      light: "#ffa879",
      dark: "#a84a21",
      contrastText: "#ffffff",
    },
  },
});

export default function App(): JSX.Element {
  const { isLoading } = useAuth0();

  return (
    <React.Fragment>
      <CssBaseline />
      <BrowserRouter>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <Menu />
            {isLoading ? (
              <LoadingIndicator data-testid="auth-spinner" />
            ) : (
              <MainPanel />
            )}
          </ThemeProvider>
        </CacheProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}
