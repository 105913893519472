import { BrowserOptions } from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const dsn = process.env.REACT_APP_SENTRY_DSN;
const env = process.env.REACT_APP_SENTRY_ENVIRONMENT;
const version = process.env.REACT_APP_SENTRY_VERSION;

export const settings: BrowserOptions = {
  dsn: dsn ? dsn : undefined,
  environment: env ? env : "local-development",
  release: version,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
};
