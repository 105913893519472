import React from "react";
import { makeStyles } from "tss-react/mui";
import CoreProfileData from "./components/CoreProfileData";
import { Container, Stack } from "@mui/material";
import { OwnUserProfile } from "../auth0";

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "0",
  },
}));

export default function Profile(props: { user: OwnUserProfile }): JSX.Element {
  const { classes } = useStyles();
  const { user: profile } = props;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Stack
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <CoreProfileData user={profile} />
      </Stack>
    </Container>
  );
}
