import { useEffect } from "react";
import { useAuth0 } from "./auth0";
import LoadingIndicator from "./components/LoadingIndicator";

export default function Authorize(): JSX.Element {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    void loginWithRedirect();
  }, [loginWithRedirect]);

  return (
    <LoadingIndicator
      backdrop
      message={"Redirecting to login screen, please wait..."}
    />
  );
}
