import { useEffect, RefObject, useState } from "react";
import { useAuth0, getCustomClaim } from "../../auth0";
import {
  isLoaded,
  settings,
  SisenseFrame,
  getSdk,
  createFrame,
  SisenseFrameEventHandler,
  EmbedSdk,
} from "../../sisense/embed";
import { Alert } from "@mui/material";
import { delay } from "../../timeout";
import { LoadingState } from "./loading-state";
import LoadingIndicator from "../../components/LoadingIndicator";
import { makeStyles } from "tss-react/mui";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles()((theme) => ({
  error: {
    height: theme.spacing(8),
    color: theme.palette.error.main,
    width: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

type FrameState = {
  sisenseFrame: SisenseFrame | null;
  attempts: number;
};

const FRAME_RENDER_ATTEMPT_LIMIT = 2;
const FRAME_RENDER_TIMEOUT_MS = 15000;

export default function EmbeddedDashboard(props: {
  frame: RefObject<HTMLIFrameElement>;
  loadingState: LoadingState | null;
  onLoadingStateChange: (state: LoadingState | null) => void;
}): JSX.Element | null {
  const { classes } = useStyles();
  const { frame: element, loadingState, onLoadingStateChange } = props;
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [isScriptLoaded, setScriptLoaded] = useState<boolean>(isLoaded());
  const [frame, setFrame] = useState<FrameState>({
    sisenseFrame: null,
    attempts: 0,
  });
  const [sdk, setSdk] = useState<EmbedSdk | null>(null);
  useEffect(() => {
    async function initialize() {
      const script = document.createElement("script");
      try {
        script.type = "text/javascript";
        script.src = `${settings.host}/js/frame.js`;
        script.async = true;
        await new Promise<void>((resolve) => {
          script.onload = () => {
            resolve();
          };
          document.body.appendChild(script);
        });
        setScriptLoaded(true);
      } catch (error) {
        onLoadingStateChange("sdk-load-error");
      }
    }

    if (isScriptLoaded) {
      return;
    }
    void initialize();
  }, [isScriptLoaded, onLoadingStateChange, setScriptLoaded]);

  useEffect(() => {
    if (!isScriptLoaded) {
      return;
    }
    const embedSdk = getSdk();
    if (!embedSdk) {
      onLoadingStateChange("sdk-load-error");
    } else {
      setSdk(embedSdk);
    }
  }, [isScriptLoaded, sdk, setSdk, onLoadingStateChange]);

  useEffect(() => {
    async function createDashboardFrame(
      sdk: EmbedSdk,
      iframe: HTMLIFrameElement,
    ) {
      const claims = await getIdTokenClaims();
      setFrame((prev) => ({
        sisenseFrame: createFrame(
          sdk,
          iframe,
          getCustomClaim(claims, "dashboard_id"),
        ),
        attempts: prev.attempts + 1,
      }));
    }

    if (loadingState === null) {
      return;
    }

    if (!element.current) {
      return;
    }

    if (sdk === null) {
      return;
    }

    if (frame.sisenseFrame !== null) {
      return;
    }

    void createDashboardFrame(sdk, element.current);
  }, [frame, setFrame, getIdTokenClaims, sdk, loadingState, element]);

  useEffect(() => {
    async function renderFrame(
      sisenseFrame: SisenseFrame,
      attempts: number,
      embedSdk: EmbedSdk,
    ) {
      onLoadingStateChange("dashboard-loading");
      const handler: SisenseFrameEventHandler = async () => {
        try {
          await sisenseFrame.dashboard.getCurrent();
          onLoadingStateChange(null);
        } catch (error) {
          onLoadingStateChange("dashboard-load-error");
        }

        sisenseFrame.dashboard.off(
          embedSdk.enums.DashboardEventType.LOADED,
          handler,
        );
      };
      sisenseFrame.dashboard.on(
        embedSdk.enums.DashboardEventType.LOADED,
        handler,
      );

      /*
        If the dashboard is not actually loaded (permission issues, 403 in XHR requests issued by the iframe, or it's not set up correctly),
        the loading indicator will stay up forever.
        There is no event that says that the EmbedSDK is 'finished', so we'll tear down the indicator after a few seconds no matter what.

        This should be enough to cover the 'blank screen' when the script is loaded, but also disappear quickly enough even if the dashboard
        is not 'fully' loaded. Unfortunately there's nothing we can do to detect it.

        On top of that, it seems that the initial load of the frame likes to hang indefinitely for no discernible reason - the 'render' call just never returns.
        Subsequent renders after the refresh seem to work fine, so it's something inside their SDK that's acting odd.

        We're going to try to re-render the frame automatically once we hit a reasonable timeout, which balances 'slow connection' users where the frame is loading,
        and the app hanging indefinitely.
        We're only try to do it a limited number of times, with the attempt limit capped by FRAME_RENDER_ATTEMPT_LIMIT.
        If that still doesn't work, we're just going to show an error and let the user refresh or contact us.
      */
      const result = await Promise.race([
        sisenseFrame.render(),
        delay(FRAME_RENDER_TIMEOUT_MS).then(() => "timeout" as const),
      ]);
      if (result === "timeout") {
        sisenseFrame.dashboard.off(
          embedSdk.enums.DashboardEventType.LOADED,
          handler,
        );
        if (attempts < FRAME_RENDER_ATTEMPT_LIMIT) {
          setFrame((prev) => ({
            sisenseFrame: null,
            attempts: prev.attempts + 1,
          }));
        } else {
          onLoadingStateChange("dashboard-load-error");
        }
      }
    }

    if (loadingState === null || loadingState === "dashboard-load-error") {
      return;
    }

    if (sdk === null) {
      return;
    }

    if (frame.sisenseFrame !== null) {
      void renderFrame(frame.sisenseFrame, frame.attempts, sdk);
    }
  }, [frame, loadingState, sdk, onLoadingStateChange, setFrame]);

  if (loadingState === "sdk-load-error") {
    return <Alert severity="error">Could not initialize the application</Alert>;
  }

  if (isLoading || !isAuthenticated) {
    return <LoadingIndicator data-testid="dashboard-loading-spinner" />;
  }

  if (!isScriptLoaded) {
    return (
      <LoadingIndicator
        data-testid="dashboard-loading-spinner"
        message="Initializing the application"
      />
    );
  }

  if (loadingState === "dashboard-load-error") {
    return (
      <div className={classes.root} data-testid="dashboard-load-error">
        <ErrorOutlineIcon fontSize="large" className={classes.error} />
        <Typography variant="body1">
          Dashboard is not set up, or failed to load. Please contact support.
        </Typography>
      </div>
    );
  }

  if (frame === null || loadingState === "dashboard-loading") {
    return (
      <LoadingIndicator
        data-testid="dashboard-loading-spinner"
        message="Pulling up the dashboard. This might take a few seconds..."
      />
    );
  }

  return null;
}
