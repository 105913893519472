import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { useAuth0 } from "./auth0";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

export default function RequireAuthentication({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const { classes } = useStyles();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: {
          returnTo: `${location.pathname}`,
        },
      });
    };
    void fn();
  }, [isLoading, isAuthenticated, loginWithRedirect, location]);

  if (!isAuthenticated) {
    return (
      <div className={classes.root}>
        <Typography variant="subtitle1">
          Redirecting to log in screen, please wait...
        </Typography>
      </div>
    );
  }

  return children;
}
