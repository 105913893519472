export type SiSenseSettings = {
  readonly frameId: string;
  readonly host: string;
  readonly dashboardId: string;
};

interface EmbedOptions {
  url: string;
  dashboard: string;
  element: HTMLElement;
  settings: {
    showToolbar: boolean;
    showLeftPane: boolean;
    showRightPane: boolean;
  };
}

export type EmbedSdk = {
  readonly SisenseFrame: SisenseFrameConstructor;
  readonly enums: {
    readonly ApplicationEventType: {
      readonly LOADED: string;
    };
    readonly DashboardEventType: {
      readonly LOADED: string;
      readonly UNLOADED: string;
    };
  };
};

type SisenseFrameConstructor = {
  new (options: EmbedOptions): SisenseFrame;
};

export type SisenseFrameEventHandler<T = unknown> = (arg?: T) => void;

type WithEventHandlers = {
  on<T = unknown>(
    eventName: string,
    handler: SisenseFrameEventHandler<T>,
  ): void;
  off<T = unknown>(
    eventName: string,
    handler: SisenseFrameEventHandler<T>,
  ): void;
};

export declare class SisenseFrame {
  render(container?: HTMLElement, hidden?: boolean): Promise<void>;
  show(): Promise<void>;
  hide(): Promise<void>;
  getSize(): Promise<{
    content: {
      width: number;
      height: number;
    };
  }>;
  app: WithEventHandlers;
  dashboard: {
    getCurrent(): Promise<unknown>;
  } & WithEventHandlers;
}

export function getSdk(): EmbedSdk | undefined {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  return (window as any)["sisense.embed"] as EmbedSdk | undefined;
}

export function isLoaded(): boolean {
  return getSdk() !== undefined;
}

export function createFrame(
  sdk: EmbedSdk,
  frame: HTMLElement,
  dashboardId?: string,
): SisenseFrame {
  return new sdk.SisenseFrame({
    dashboard: dashboardId ?? settings.dashboardId,
    element: frame,
    url: settings.host,
    settings: {
      showLeftPane: true,
      showRightPane: true,
      showToolbar: true,
    },
  });
}

if (!process.env.REACT_APP_DEFAULT_DASHBOARD_ID) {
  throw new Error(
    "REACT_APP_DEFAULT_DASHBOARD_ID environmental variable is missing",
  );
}

export const settings = Object.freeze<SiSenseSettings>({
  frameId: "sisense-iframe",
  dashboardId: process.env.REACT_APP_DEFAULT_DASHBOARD_ID,
  host:
    process.env.REACT_APP_SISENSE_HOST ?? "https://dev.staging.io.selladore.co",
});
